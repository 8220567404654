<template>
	<div class="Counter">
		
		
		<div class="Count">
			
			<div class="Column NoLeft">
				<div class="Left">
					<span class="el-icon-s-claim" style="color: rgba(56,203,28,1);"></span>
				</div>
				<div class="Right">
					<li>已充值金额</li>
					<span style="color: rgba(56,203,28,1);">{{SumRrechaged}}</span>
				</div>
			</div>
			
			
			<div class="Column">
				<div class="Left">
					<span class="el-icon-s-finance" style="color: rgba(0,158,255,1);"></span>
				</div>
				<div class="Right">
					<li>已提现金额</li>
					<span style="color: rgba(0,158,255,1);">{{SumWithdrawed}}</span>
				</div>
			</div>
			
			<div class="Column">
				<div class="Left">
					<span class="el-icon-s-marketing"></span>
				</div>
				<div class="Right">
					<li>提现中金额</li>
					<span>{{SumWithdrawing}}</span>
				</div>
			</div>
			
			<div class="Column">
				<div class="Left">
					<span class="el-icon-s-custom" style="color: rgba(207,171,248,1);"></span>
				</div>
				<div class="Right">
					<li>打款账号</li>
					<span style="color: rgba(207,171,248,1);">
						<el-button size="mini" type="primary" @click="$Jump('/do/finance/withdraw_pay_account_list')">管理</el-button>
					</span>
				</div>
			</div>
			
		</div>
		
		<div class="CountList">
			
			<ul>
				<h3>交易流水 <span><el-button size="mini" type="primary" @click="$Jump('/do/finance/trade_list')">全部流水</el-button></span></h3>
				<li v-for="(item,itemI) in TradeDetailList" :key="itemI">
					<span class="Name">{{item.TypeName}}</span>
					<span class="Money" style="color: #409EFF;">
						<template v-if="item.Amount > 0">
							<em>{{item.Amount}}</em>
						</template>
						<template v-else>
							<i style="font-style: normal;color: #888888;">{{item.Amount}}</i>
						</template>
					</span>
					<span class="Time">{{item.CreatedAt}}</span>
				</li>
			</ul>
			
			<ul class="R">
				<h3>充值记录 <span><el-button size="mini" type="success" @click="$Jump('/do/finance/recharge_list')">全部充值</el-button></span></h3>
				<li v-for="(item,itemI) in RechargeList" :key="itemI">
					<span class="Name">{{item.StatusName}}</span>
					<span class="Money"><em>{{item.Amount}}</em></span>
					<span class="Time">{{item.CreatedAt}}</span>
				</li>
			</ul>
			
			<ul class="R">
				<h3>提现记录<span><el-button size="mini" type="danger" @click="$Jump('/do/finance/withdraw_list')">全部提现</el-button></span></h3>
				<li v-for="(item,itemI) in WithdrawList" :key="itemI">
					<span class="Name">{{item.WithdrawAccountTypeName}}</span>
					<span class="Money" style="color: #F56C6C;">-<em>{{item.WithdrawAmount}}</em> <b style="font-weight: normal;font-size: 0.8rem;color: #888888;">{{item.StatusName}}</b></span>
					<span class="Time">{{item.CreatedAt}}</span>
				</li>
			</ul>
			
		</div>
		
	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Checkbox} from 'element-ui'
	export default {
	  name: 'FinanceCount',
	  props: {
	  },
	  data() {
	      return {
			  TradeDetailList:[],
			  WithdrawList:[],
			  RechargeList:[],
			  SumWithdrawing:0.00,
			  SumWithdrawed:0.00,
			  SumRrechaged:0.00,//已充值金额
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-checkbox':Checkbox
	  },
	  created() {
	  	this.GetLastWithdrawList()
		this.GetLastTrade()
		this.GetLastRecharge()
		this.SumWithdraw('withdraw_amount',50)//统计提现中
		this.SumWithdraw('withdraw_amount',100)//统计提现完成
		this.SumRecharge()
	  },
	  methods:{
		  GetLastTrade(){ //获取最新交易流水
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceTradeDetail',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:20,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  				this.TradeDetailList = res.Data.TradeDetailList
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetLastRecharge(){ //获取最新交易流水
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceRecharge',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:20,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  				this.RechargeList = res.Data.RechargeList
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetLastWithdrawList(){ //获取提现记录
			  let data = {
			  	Service:'Finance',
			  	Class: 'FinanceWithdraw',
			  	Action: 'List',
			  	Page:1,
			  	PageSize:20,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
				this.WithdrawList = res.Data.FinanceWithdrawList
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  SumWithdraw(_clumn,_status){ //
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceWithdraw',
		  			  	Action: 'Sum',
		  			  	Status:_status,
		  			  	SumCloumn:_clumn,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  				
		  				if(_clumn == 'withdraw_amount' && _status == 50){
							console.log('50')
							this.SumWithdrawing = res.Data.Total
							console.log(this.SumWithdrawing)
						}else if(_clumn == 'withdraw_amount' && _status == 100){
							console.log('100')
							this.SumWithdrawed = res.Data.Total
							console.log(this.SumWithdrawed)
						}
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  SumRecharge(){ //
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceRecharge',
		  			  	Action: 'Sum',
		  			  	Status:100,
		  			  	SumCloumn:"amount",
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  				
		  				this.SumRrechaged = res.Data.Total
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
	  }
	}
</script>

<style scoped>
.Counter{
	margin: 20px;
	padding: 20px;
}
.Count{
	display: flex;
	justify-content: space-between;
}
.Count .Column{
	background-color: #FFFFFF;
	flex: 1;
	margin-left: 20px;
	padding: 20px;
	display: flex;
	align-items: center;
}
.Count .NoLeft{
	margin-left: 0px;
}
.Count .Column .Left{
	margin-right: 20px;
	color: rgba(228,0,0,1);
}
.Count .Column .Left span{
	font-size: 2.2rem;
}
.Count .Column .Right li{
	color: rgba(0,0,0,0.4);
	margin-bottom: 5px;
	font-size: 1.2rem;
}
.Count .Column .Right span{
	display: block;
	font-size: 1.4rem;
	color: rgba(228,0,0,1);
}

.CountList{
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}
.CountList ul{
	flex: 1;
	background-color: #FFFFFF;
	padding-bottom: 10px;
}
.CountList ul.R{
	margin-left: 10px;
}
.CountList ul h3{
	font-weight: normal;
	padding: 0px 20px;
	color: rgba(0,0,0,0.4);
	font-size: 1.0rem;
}
.CountList ul h3 span{
	float: right;
	cursor: pointer;
	color: rgba(228,0,0,1);
}
.CountList ul li{
	padding: 10px 20px;
	display: flex;
	align-items: center;
	border-top: 1px solid rgba(0,0,0,0.1);
}
.CountList ul li span.Name{
	flex: 1;
	color: rgba(0,0,0,0.5);
}
.CountList ul li span.Time{
	width: 120px;
	color: rgba(0,0,0,0.3);
}
.CountList ul li span.Money{
	width: 120px;
	font-size: 1.2rem;
	color: rgba(0,214,0,1);
}
</style>
